<template>
  <div>
    <v-card flat class="pl-1">
      <div three-line subheader v-if="listOfNotes.length" class="mt-4" dense>
        <v-card outlined class="mt-1" v-for="(item, index) in listOfNotes" :key="index" link>
          <v-layout>
            <v-flex xs11>
              <div class="d-flex ml-2">
                <div class="pa-0 mt-2">
                  <v-avatar color="primary" class="pa-0" size="30">
                    <span class="mx-auto white--text">
                      <strong>{{ item.user_info && item.user_info.lastname ? `${item.user_info.firstname.charAt(0).toUpperCase()}${item.user_info.lastname.charAt(0).toUpperCase()}` : item.user_info.firstname.charAt(0).toUpperCase() }}</strong>
                    </span>
                  </v-avatar>
                </div>
                <div class="ml-3 caption">
                  <strong>{{item.user_info && item.user_info.lastname ? `${item.user_info.firstname} ${item.user_info.lastname}` : item.user_info.firstname }}</strong>
                  <div>{{ $formatter.formatDate(item.modified_at, 'DD.MM.YYYYTHH:mm:ss', `${userDetails.dateformat} HH:mm`) }}</div>
                </div>
              </div>
            </v-flex>
            <v-flex lg1 class="mt-1 justify-end">
              <div class="d-flex align-center justify-end">
                <v-btn icon small :color="$vuetify.theme.dark ? '' : systemDetails.themecolor">
                  <v-icon @click="editNotes(index)"
                    :color="$vuetify.theme.dark ? '' : systemDetails.themecolor">mdi-pencil-circle-outline</v-icon>
                </v-btn>
                <v-btn icon small :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" class="">
                  <v-icon @click="deleteSingleNotes(item._id)" color="error">mdi-delete-circle-outline</v-icon>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
          <v-divider class="ml-2 mt-2"></v-divider>
          <v-layout class="mt-2">
            <v-flex lg12 class="pl-4 caption" grow>
              <p v-html="item.note"></p>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
      <template v-else>
        <v-alert text prominent icon="mdi-alert" type="info" class="mt-3 mr-1">
          <v-row align="center">
            <v-col class="grow">{{ $t('noNotesAdded') }}</v-col>
          </v-row>
        </v-alert>
      </template>
    </v-card>
    <v-speed-dial bottom right fixed class="mb-12">
      <template v-slot:activator>
        <v-btn small :color="systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" :style="{ color: systemDetails.textcolor ? systemDetails.textcolor : 'white' }"
          fab dark @click="addCommentsDialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>
    <v-dialog v-model="addCommentsDialog" persistent width="800" class="mt-0">
      <v-card>
        <v-card-title :style="$vuetify.theme.dark ? '' : `background-color: ${systemDetails.themecolor}; color: ${systemDetails.textcolor}`">
          {{ notesObj._id ? $t('update') : $t('add') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <div v-if="addCommentsDialog" class="commentBox  font-weight-regular text--primary">
            <ckeditor :editor="editor" v-model="notesObj.notes" :config="noteConfig" ref="editor"></ckeditor>
            <v-flex class="mt-1 text-right">
              <v-btn-toggle v-model="toggle_exclusive" class="justify-right">
                <v-btn small @click="saveNotes" v-if="!saveNotesLoader" color="success">
                  <v-icon color="white">mdi-check</v-icon>
                </v-btn>
                <v-btn small v-else color="success">
                  <v-progress-circular color="white" indeterminate :width="3" size="20"></v-progress-circular>
                </v-btn>
                <v-btn class="ml-1" small @click="addCommentsDialog = false, notesObj = {}" color="error">
                  <v-icon color="white">mdi-close</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-flex>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="text-center">
            <v-btn small color="error" id="deleteClose" @click="addCommentsDialog=false">{{ $t('close') }}</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Editor from '@/ckeditor'
export default {
  props: ['modelObj'],
  data () {
    return {
      listOfNotes: [],
      saveNotesLoader: false,
      showNoteBox: false,
      editor: Editor,
      editorConfig: {
        removePlugins: ['ImageUpload', 'ElementsPath'],
        sharedSpaces: {
          top: 'toolbarLocation'
        },
        toolbar: {
          items: ['bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
        }
      },
      noteConfig: {
        height: '250px',
        sharedSpaces: {
          top: 'toolbarLocation'
        },
        toolbar: {
          items: ['bold', 'italic', 'outdent', 'indent', '|', 'bulletedList', 'numberedList', '|', 'insertTable', 'blockQuote', 'undo', 'redo']
        }
      },
      notesObj: {
        _id: '',
        notes: ''
      },
      toggle_exclusive: 2,
      NOTES_MODULE: 'ticketnotes',
      addCommentsDialog: false
    }
  },
  computed: {
    ...mapGetters(['getUsers', 'getsignalRConnection', 'systemDetails'])
  },
  mounted () {
    this.getListOfNotes()
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.NOTES_MODULE) {
        const payload = { ids: data.ids, url: `${this.NOTES_MODULE}/delete_multiple`, module: this.NOTES_MODULE }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$root.$on('loadComments', (data) => {
      this.getAllComments(data)
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.NOTES_MODULE) {
        this.selected = []
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.$t('deleteMsg') })
        this.$store.commit('hideDialog')
        this.getListOfNotes()
      }
    })
  },
  methods: {
    saveNotes () {
      const model = {
        _id: this.$formatter.cloneVariable(this.notesObj._id) ? this.$formatter.cloneVariable(this.notesObj._id) : undefined,
        ticket_id: this.modelObj._id,
        user_id: this.userDetails._id,
        note: this.$formatter.cloneVariable(this.notesObj.notes)
      }
      this.$api.execute(model._id ? 'put' : 'post', model._id ? `/ticketnotes/${model._id}` : '/ticketnotes', model).then(response => {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'savedSuccess' })
        // if (this.modelObj.assignedto) {
        //   let assignedTo = this.$formatter.cloneVariable(this.modelObj.assignedto)
        //   assignedTo = assignedTo.split('_')
        //   if (assignedTo[0] === 'group') {
        //     let item = this.getListOfGroups.find(x => x._id == assignedTo[1])
        //     let usersarray = []
        //     // let query = {
        //     //   filter: `groupid eq ${assignedTo[1]} and isactive eq 1`
        //     // }
        //     let  msg = `${this.$store.state.auth.userDetails.name} ${ model._id ? this.$t('updated') : this.$t('added') } ${this.$t('noteIn')} ${this.modelObj.number}`
        //     this.$api.execute('get', `usergroups/get_by_group/${assignedTo[1]}`).then(result => {
        //       usersarray = result.data.map(x => x.user_id)
        //     }).finally(() => {
        //       this.getsignalRConnection.invoke('SendNotification', usersarray, msg, response.data._id)
        //     })
        //   } else {
        //     if (this.$store.state.auth.userDetails._id != assignedTo[1]) {
        //       let  msg = `${this.$store.state.auth.userDetails.name} ${ model._id ? this.$t('updated') : this.$t('added') } ${this.$t('noteIn')} ${this.modelObj.number}`
        //       this.getsignalRConnection.invoke('SendNotification', [assignedTo[1]], msg, model.ticket_id)
        //     }
        //   }
        // }
        // this.showNoteBox = false
        this.notesObj = {}
        this.addCommentsDialog = false
        this.getListOfNotes()
      })
    },
    getListOfNotes () {
      const query = [{
        $match: { $and: [{ Ticket_Id: `${this.modelObj._id}` }, { User_Id: `${this.userDetails._id}` }] }
      }, {
        $lookup: {
          let: { userId: '$User_Id' },
          from: 'UserSetting',
          pipeline: [
            { $match: { $expr: { $eq: ['$_id', { $convert: { input: '$$userId', to: 'objectId', onError: { error: true }, onNull: { isnull: true } } }] } } },
            { $project: { _id: 1, FirstName: 1, LastName: 1 } }
          ],
          as: 'user_info'
        }
      }]
      this.$api.execute('post', '/ticketnotes/query', query).then((response) => {
        response.data.forEach((note) => {
          note.user_info = note.user_info[0]
        })
        this.listOfNotes = response.data
      })
    },
    editNotes (index) {
      const note = this.$formatter.cloneVariable(this.listOfNotes[index])
      this.notesObj._id = note._id
      this.notesObj.notes = note.note || ''
      this.addCommentsDialog = true
      this.autoFocusOnField()
    },
    autoFocusOnField () {
      setTimeout(() => {
        var div = document.getElementsByClassName('ck-editor__editable_inline')
        if (div && div[div.length - 1]) div[div.length - 1].focus()
      }, 100)
    },
    deleteSingleNotes (id) {
      const ids = [id]
      const data = { module: this.NOTES_MODULE, ids }
      this.$eventBus.$emit('deleteItems', data)
    }
  }
}
</script>
